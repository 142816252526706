// Packages
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types'

// Context
import { useNav } from 'context/Nav';

// Other
import './styles.css';

const CheckedLink = ({ children, className, label, onClick, style, to }) => {

    const { setUnsavedChanges, unsavedChanges } = useNav();
    const navigate = useNavigate();

    const handleNavigation = () => {

        if (unsavedChanges && window.confirm("You have unsaved changes. Are you sure you want to leave?")) {

            setUnsavedChanges(false);
            navigate(to);
            if( onClick ) {
                onClick();
            }
        }
        else if( ! unsavedChanges ) {

            navigate(to);
            if( onClick ) {
                onClick();
            }
        }
	};

    return(
        <div 
            className={`CheckedLink ${className}`}
            onClick={handleNavigation} 
            style={style}
        >{children} {label ?? ''}</div>
    )
}

CheckedLink.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
    to: PropTypes.string
}

CheckedLink.defaultProps = {
    children: null,
    className: '',
    label: '',
    onClick: null,
    style: {},
    to: '/'
}

export default CheckedLink;