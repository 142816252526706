// Packages
import { Routes, Route } from "react-router-dom"

// Screens
import ClientsListScreen from "modules/Clients/screens/List"


const FormsRoutes = () => 
{
	return (
		<Routes>
			<Route path="/*" element={<ClientsListScreen />}/>
		</Routes>
	)
}

export default FormsRoutes;