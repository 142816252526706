// Packages
import PropTypes from 'prop-types';

// Components
import Container from 'components/BTDUI/Container';
import ScreenTitle from 'components/ScreenTitle';
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';

// other
import './styles.css';

const SubmissionsLayout = ({ children, client, isLoading, name }) => {

	return (
		<div className='SubmissionsLayout'>
			<Container>

				<ScreenTitle title={isLoading ? <BTDSkeleton /> : client ? client.name + ' - ' + name + ' Submissions' : name + ' Submissions'}/>

				{children}

			</Container>
			
        </div>
	)
}

SubmissionsLayout.propTypes = {
	children: PropTypes.node,
	title: PropTypes.string
}

SubmissionsLayout.defaultProps = {
	children: null,
	title: ''
}

export default SubmissionsLayout