// Packages
import ListItem from 'modules/Forms/components/SubmissionsListItem';
import PropTypes from 'prop-types';

// Other
import './styles.css';
import BTDProgress from 'components/BTDUI/BTDProgress';

const SubmissionsList = ({ isLoading, items }) => {

	// Loading State
	if ( isLoading )
	{
		return (
			<div style={{alignItems: 'center', display: 'flex', justifyContent: 'center', padding: '40px'}}>
				<BTDProgress height={40} type='circular' width={40} />
			</div>
		)
	}


	// Empty State
	if ( items.length === 0 )
	{
		return (
			<h3 className='opacity italic'>No submissions to show here</h3>
		)
	}


	// Data State
	return (
		<div className='FormsList'>

			{items.map((item, index) => {

				return (
						<div key={index}>
							<ListItem 
								{...item} 
								isLoading={false}
							/>
						</div>
				)
				
			})}
	
		</div>
	)
}

SubmissionsList.propTypes = {
	isLoading: PropTypes.bool,
	items: PropTypes.array,
}

SubmissionsList.defaultProps = {
	isLoading: true,
	items: [],
}

export default SubmissionsList;