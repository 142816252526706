// Packages
import { useState } from 'react'

// Components
import AddIcon from 'components/BTDUI/icons/BTDFAB';
import ClientsLayout from 'modules/Clients/layouts';
import ClientsList from 'modules/Clients/components/List';
import ClientCreateScreen from '../Create';

// Other
import useApi from 'hooks/useApi';

const ClientsListScreen = () => {

    const { data: items, isLoading, onChange } = useApi('/clients');

    const [formActive, setFormActive] = useState(false);

    return(
        <ClientsLayout>

            <ClientsList 
                items={items} 
                isLoading={isLoading} 
                onChange={() => {onChange()}}
            />

            <AddIcon onClick={() => {
                setFormActive(true);
            }} />

            {formActive &&
                <ClientCreateScreen
                    onClose={() => {
                        setFormActive(false);
                    }} 
                    onSubmit={() => {
                        onChange();
                        setFormActive(false);
                    }} 
                />
            }

        </ClientsLayout>
    )

}

export default ClientsListScreen;