// Packages
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import FormTemplate from 'components/BTDUI/FormTemplate';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDButton from 'components/BTDUI/BTDButton';
import BTDSelectField from 'components/BTDUI/fields/BTDSelectField';
import BTDCheckboxField from 'components/BTDUI/fields/BTDCheckboxField';


const FieldForm = ({ errors, field, onClose, onSubmit, type, }) => {

    const [data, setData] = useState({})
	const [changedData, setChangedData] = useState({})


    // Load form values into form
	useEffect(() => {

		if ( field )
		{
            setData(field);
		}

	}, [field]);


    return(
        <FormTemplate onClose={() => {onClose()}}>

            <h3 className="form__title medium">{type === 'create' ? 'Create field' : 'Edit field'}</h3>
        
            <BTDTextField 
                error={errors && errors.name ? errors.name : null} 
                label='Name'
                onChange={(value) => {
                    setData({...data, name: value});
                    setChangedData({...changedData, name: value});
                }} 
                required={true}
                value={data.name ?? ''} 
            />

            <BTDTextField 
                error={errors && errors.label ? errors.label : null} 
                label='Label'
                onChange={(value) => {
                    setData({...data, label: value});
                    setChangedData({...changedData, label: value});
                }} 
                required={true}
                value={data.label ?? ''} 
            />

            <BTDSelectField
                error={errors && errors.type ? errors.type : null} 
                label='Type'
                onChange={(value) => {
                    setData({...data, type: value});
                    setChangedData({...changedData, type: value});
                }} 
                options={[
                    {id: 'text', name: 'Text'},
                    {id: 'email', name: 'Email'},
                ]}
                required={true}
                value={data.type ?? ''} 
            />

            <BTDCheckboxField
                checked={data.is_required ?? false} 
                error={errors && errors.is_required ? errors.is_required : null} 
                label='Required?'
                onChange={(value) => {
                    setData({...data, is_required: value});
                    setChangedData({...changedData, is_required: value});
                }} 
            />

            <BTDButton 
                formButton={true}
                label={type === 'create' ? 'Save field' : 'Update field'}
                onClick={() => {onSubmit(changedData)}} 
            ></BTDButton>

        </FormTemplate>
    )

}

FieldForm.propTypes = {
	errors: PropTypes.object,
	field: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    formId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    type: PropTypes.string
}

FieldForm.defaultProps = {
    errors: {},
    field: {},
    formId: '',
	onSubmit: () => {alert('onSubmit is not set')},
	onClose: () => {alert('onClose is not set')},
    type: 'create'
}

export default FieldForm;