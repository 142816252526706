// Packages
import PropTypes from 'prop-types'

// Components
import ClientForm from 'modules/Clients/components/Form';

// Other
import useApi from 'hooks/useApi';

const ClientCreateScreen = ({ onClose, onSubmit }) => {

    const { post: createItem, errors } = useApi('/clients',  false)

	const submit = async (values) => {

        if( await createItem(values, 'Client created!') ) {
    
            onSubmit();
        }
    }


    return(
        <ClientForm 
            errors={errors} 
            onClose={onClose} 
            onSubmit={submit} 
            type='create' 
        />
    )
}

ClientCreateScreen.propTypes = {
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
}

ClientCreateScreen.defaultProps = {
	onClose: () => {alert('onClose is not set!')},
    onSubmit: () => {alert('onSubmit is not set!')}
}

export default ClientCreateScreen;