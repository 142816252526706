// Packages
import PropTypes from 'prop-types'

// Components
import FieldForm from 'modules/Fields/components/Form';

// Other
import useApi from 'hooks/useApi';

const FieldEditScreen = ({  fieldId, formId, onClose, onSubmit }) => {

    const { data: item, put: updateItem, errors } = useApi('/forms/' + formId + '/fields/' + fieldId);

	const submit = async (values) => {

        if( await updateItem(values, 'Field updated!')) {

            onSubmit();
        }
    }

    return(
        <FieldForm 
            errors={errors} 
            field={item} 
            onClose={onClose} 
            onSubmit={submit} 
            type='edit' 
        />
    )
}

FieldEditScreen.propTypes = {
    formId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fieldId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onClose: PropTypes.func,
    onSubmit: PropTypes.func,
}

FieldEditScreen.defaultProps = {
    formId: null,
    fieldId: null,
	onClose: () => {alert('onClose is not set!')},
    onSubmit: () => {alert('onSubmit is not set!')}
}

export default FieldEditScreen;