// Packages
import PropTypes from 'prop-types';

// other
import './styles.css';

const EllipsisIcon = ({ colour, height, onClick, style, width }) => {
    
    return(
        <div className="EllipsisIcon" onClick={onClick} style={style}>
            <svg height={height} viewBox="0 0 96 416" width={width} version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="dots" fill={colour} fillRule="nonzero">
                        <path d="M0,208 C0,234.464 21.536,256 48,256 C74.464,256 96,234.464 96,208 C96,181.536 74.464,160 48,160 C21.536,160 0,181.536 0,208 Z" id="XMLID_294_"></path>
                        <path d="M0,368 C0,394.464 21.536,416 48,416 C74.464,416 96,394.464 96,368 C96,341.536 74.464,320 48,320 C21.536,320 0,341.536 0,368 Z" id="XMLID_295_"></path>
                        <path d="M0,48 C0,74.464 21.536,96 48,96 C74.464,96 96,74.464 96,48 C96,21.536 74.464,0 48,0 C21.536,0 0,21.536 0,48 Z" id="XMLID_297_"></path>
                    </g>
                </g>
            </svg>
        </div>
    )
}

EllipsisIcon.propTypes = {
    colour: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    style: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

EllipsisIcon.defaultProps = {
    colour: "currentColor",
    height: "25px",
    onClick: () => {},
    style: {},
    width: "25px", 
}

export default EllipsisIcon;