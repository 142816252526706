// Packages
import { Routes, Route } from "react-router-dom"

// Screens
import FormsListScreen from "modules/Forms/screens/List"
import FormViewScreen from "modules/Forms/screens/View"
import FormSubmissionsScreen from "modules/Forms/screens/Submissions"


const FormsRoutes = () => 
{
	return (
		<Routes>
			<Route path="/forms/:formId/submissions/*" element={<FormSubmissionsScreen />}/>
			<Route path="/forms/:formId/*" element={<FormViewScreen />}/>
			<Route path="/forms" element={<FormsListScreen />}/>
			<Route path="/*" element={<FormsListScreen />}/>
		</Routes>
	)
}

export default FormsRoutes;