import './styles.css';

const Footer = () => {

	return (
		<footer className="Footer">

			&copy; 2023 Behind The Dot

		</footer>
	)
}

export default Footer;