// Packages
import ListItem from 'modules/Forms/components/ListItem';
import PropTypes from 'prop-types';

// Other
import './styles.css';

const FormsList = ({ isLoading, items, onChange }) => {

	// Loading State
	if ( isLoading )
	{
		return (
			<div className='FormsList'>
					<ListItem isLoading={true}/>
			</div>
		)
	}


	// Empty State
	if ( items.length === 0 )
	{
		return (
			<h3 className='opacity italic'>No forms to show here</h3>
		)
	}


	// Data State
	return (
		<div className='FormsList'>

			{items.map((item, index) => {

				return (
						<div key={index}>
							<ListItem 
								{...item} 
								isLoading={false}
								onChange={() => {onChange()}}
							/>
						</div>
				)
				
			})}
	
		</div>
	)
}

FormsList.propTypes = {
	isLoading: PropTypes.bool,
	items: PropTypes.array,
	onChange: PropTypes.func,
}

FormsList.defaultProps = {
	isLoading: false,
	items: [],
	onChange: () => {'onChange is not set!'},
}

export default FormsList;