import { useState } from 'react';
import PropTypes from 'prop-types';

// Other
import './styles.css'; 

const BTDTooltip = ({ text, children }) => {

    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div className="BTDTooltip__container" onMouseEnter={() => {setShowTooltip(true)}} onMouseLeave={() => {setShowTooltip(false)}}>
            {children}

            {showTooltip && 
                <div className="BTDTooltip">
                    <div className="BTDTooltip__arrow"></div>
                    <div className="BTDTooltip__content">{text}</div>
                </div>
            }
        </div>
    );
};

BTDTooltip.propTypes = {
    text: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

BTDTooltip.defaultProps = {
    text: '',
    children: null
}

export default BTDTooltip;
