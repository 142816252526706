// Packages
import { Routes, Route } from "react-router-dom"

// Routes
import ClientRoutes from "modules/Clients/routes"
import FormRoutes from "modules/Forms/routes"

const MainRoutes = () => 
{
	return (
		<Routes>
			<Route path="/clients/*" element={<ClientRoutes />}/>
			<Route path="/*" element={<FormRoutes />}/>
		</Routes>
	)
}

export default MainRoutes;