import { createContext, useContext, useState, useEffect } from 'react';
import Snackbar from 'components/BTDUI/BTDSnackbar';

const SnackbarContext = createContext();


export const SnackbarProvider = ({ children }) => {

    const [snackbar, setSnackbar] = useState(null);
    const [snackbarType, setSnackbarType] = useState('');

    useEffect(() => {

        if (snackbar) {
            const timer = setTimeout(() => {
            closeSnackbar();
            }, 5000); // Auto-dismiss after 5 seconds

            return () => {
                clearTimeout(timer);
            };
        }

    }, [snackbar]);

    const showSnackbar = (message, type) => {
        setSnackbar(message);
        setSnackbarType(type);
    };

    const closeSnackbar = () => {
        setSnackbar(null);
        setSnackbarType('');
    };

    return (
    <SnackbarContext.Provider value={{showSnackbar}}>
        {children}
        {snackbar && <Snackbar message={snackbar} type={snackbarType} onClose={closeSnackbar} />}
    </SnackbarContext.Provider>
    );
};

export const useSnackbar = () => {
    return useContext(SnackbarContext);
};
