// Packages
import PropTypes from 'prop-types';

// other
import './styles.css';

function BTDPagination({ currentPage, totalPages, onChange, style }) {

    const handlePageChange = (newPage) => {

        if (newPage >= 1 && newPage <= totalPages) {

            onChange(newPage);
        }
    };

    const renderPageNumbers = () => {

        const pageNumbers = [];

        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <li
                    key={i}
                    className={`BTDPagination__list__number ${currentPage === i ? 'active' : ''}`}
                    onClick={() => handlePageChange(i)}
                    >
                    {i}
                </li>
            );
        }
        return pageNumbers;
    };

    return (
        <div className="BTDPagination" style={style}>

            <div
                className={`BTDPagination__button BTDPagination__button-prev ${currentPage === 1 ? 'disabled' : ''}`}
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
            />

            <ul className="BTDPagination__list">
                {renderPageNumbers()}
            </ul>

            <div
                className={`BTDPagination__button BTDPagination__button-next ${currentPage === totalPages ? 'disabled' : ''}`}
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
            />

        </div>
    );
}

BTDPagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    style: PropTypes.object
};

BTDPagination.defaultProps = {
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    onChange: () => {alert('onChange is not set!')},
    style: {}
}

export default BTDPagination;
