// Packages
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Other
import './styles.css'; 

const BTDProgress = ({ style, type, value, width }) => {

    const [progress, setProgress] = useState(value || 100);

    useEffect(() => {

        const interval = setInterval(() => {

            if (progress < 100) {

                setProgress(progress + 1);
            }
        }, 30); // Adjust the interval for the loading animation

        return () => {
            clearInterval(interval);
        };
    }, [progress]);

    const linearProgressBar = (
        <div className="BTDProgress BTDProgress-linear" style={style}>
            <div className="BTDProgress__bar" style={{ width: `${progress}%` }}></div>
        </div>
    );

    const circularProgressBar = (
        <div className="BTDProgress BTDProgress-circular" style={style}>
            <div className="BTDProgress__spin" style={{
                border: `${width / 10}px solid #f3f3f3`, 
                borderTop: `${width / 10}px solid #1976d2`,
                width: `${width}px`,
                height: `${width}px`
            }}></div>
        </div>
    );

    return type === 'circular' ? circularProgressBar : linearProgressBar;
};

BTDProgress.propTypes = {
    style: PropTypes.object,
    type: PropTypes.oneOf(['linear', 'circular']),
    value: PropTypes.number,
    width: PropTypes.number
};

BTDProgress.defaultProps = {
    style: {},
    type: 'linear',
    value: 100,
    width: 40
};

export default BTDProgress;
