// Packages
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import FormTemplate from 'components/BTDUI/FormTemplate';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDButton from 'components/BTDUI/BTDButton';
import BTDTextareaField from 'components/BTDUI/fields/BTDTextareaField';

// Other
import useApi from 'hooks/useApi';
import BTDSelectField from 'components/BTDUI/fields/BTDSelectField';


const FormForm = ({ errors, form, onClose, onSubmit, type, }) => {

    const { data: clients } = useApi('/clients');

    const [data, setData] = useState({})
	const [changedData, setChangedData] = useState({})

    // Load form values into form
	useEffect(() => {

		if ( form )
		{
            setData(form);
		}

	}, [form]);


    return(
        <FormTemplate onClose={() => {onClose()}}>

            <h3 className="form__title medium">{type === 'create' ? 'Create form' : 'Edit form'}</h3>
        
            <BTDSelectField
                error={errors && errors.client_id ? errors.client_id : null} 
                label='Client'
                onChange={(value) => {
                    setData({...data, client_id: value});
                    setChangedData({...changedData, client_id: value});
                }} 
                options={clients}
                required={true}
                value={data.client_id ?? ''} 
            />

            <BTDTextField 
                error={errors && errors.name ? errors.name : null} 
                label='Form name'
                onChange={(value) => {
                    setData({...data, name: value});
                    setChangedData({...changedData, name: value});
                }} 
                required={true}
                value={data.name ?? ''} 
            />

            <BTDTextareaField
                error={errors && errors.recipients ? errors.recipients : null} 
                label='Recipients'
                onChange={(value) => {
                    setData({...data, recipients: value});
                    setChangedData({...changedData, recipients: value});
                }} 
                rows={3}
                value={data.recipients ?? ''} 
            />

            <BTDTextField 
                error={errors && errors.redirect_url ? errors.redirect_url : null} 
                label='Redirect URL'
                onChange={(value) => {
                    setData({...data, redirect_url: value});
                    setChangedData({...changedData, redirect_url: value});
                }} 
                required={true}
                value={data.redirect_url ?? ''} 
            />

            <BTDButton 
                formButton={true}
                label={type === 'create' ? 'Save form' : 'Update form'}
                onClick={() => onSubmit(changedData)} 
            ></BTDButton>

        </FormTemplate>
    )

}

FormForm.propTypes = {
	errors: PropTypes.object,
	form: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    type: PropTypes.string
}

FormForm.defaultProps = {
    errors: {},
    form: null,
	onSubmit: () => {alert('onSubmit is not set')},
	onClose: () => {alert('onClose is not set')},
    type: 'create'
}

export default FormForm;