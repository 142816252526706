// Packages
import PropTypes from 'prop-types';

// other
import './styles.css';
// import ClearIcon from '../icons/ClearIcon';

const BTDSnackbar = ({ message, type, onClose }) => {

    return (
        <div className={`BTDSnackbar ${type}`}>
            {type === 'success' ?
                <div className='BTDSnackbar__icon BTDSnackbar__icon-tick'></div>
            :
                <div className='BTDSnackbar__icon BTDSnackbar__icon-exclamation'></div>
            }
            <span className="BTDSnackbar__text">{message}</span>
            {/* <ClearIcon colour='#fff' onClick={onClose} style={{cursor: 'pointer', marginLeft: '8px'}}/> */}
        </div>
    );
};

BTDSnackbar.propTypes = {
    message: PropTypes.string,
    onClose: PropTypes.func,
    type: PropTypes.string
}

BTDSnackbar.defaultProps = {
    message: '',
    onClose: () => {},
    type: 'info'
}

export default BTDSnackbar;