// Components
import BTDProgress from 'components/BTDUI/BTDProgress';
import Header from 'components/Header';
import Footer from 'components/Footer';
import MainRoutes from 'other/mainroutes';
// Context
import { useUI } from 'context/UI';

// Other
import 'assets/styles.css';

function App() {

	const { getLoading } = useUI();


	return (
	<>
		{getLoading() &&
		<>
			<div style={{ 
				left: '0',
				position: 'absolute', 
				top: '0',
				width: '100%', 
				}}
			>
				<BTDProgress />
			</div>
		</>
		}

		<Header />
		
		<MainRoutes/>

		<Footer />
    </>
	);
}

export default App;
