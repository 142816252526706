// Packages
import { useState } from 'react'
import PropTypes from 'prop-types';

// Components
import BTDMenu from 'components/BTDUI/BTDMenu';
import EditIcon from 'components/BTDUI/icons/EditIcon';
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import ClearIcon from 'components/BTDUI/icons/ClearIcon';
import EllipsisIcon from 'components/BTDUI/icons/EllipsisIcon';
import FormEditScreen from 'modules/Forms/screens/Edit';
import { Link } from 'react-router-dom';
import MailIcon from 'components/BTDUI/icons/MailIcon';

// Other
import './styles.css'
import useApi from 'hooks/useApi';

const FormListItem = ({ client, id, isLoading, name, onChange, number_of_submissions }) => {

	const { destroy } = useApi('/forms/' + id, false);

	const [formActive, setFormActive] = useState(false);

	const deleteItem = async () => {

		await destroy('form', 'Form Deleted!');

		onChange();
    }

	return (
		<>
		<Link className="FormListItem card" to={`/forms/${id}`}>

			<p className='black medium'>{! isLoading ? client.name ?? '' : <BTDSkeleton />}</p>

			<p className='black medium'>{! isLoading ? name : <BTDSkeleton />}</p>

			<p className='black medium'>Submissions: {! isLoading ? number_of_submissions : <BTDSkeleton />}</p>

			<BTDMenu icon={<EllipsisIcon colour='#253551' height="18px" onClick={(e) => {e.preventDefault()}} />} style={{display: 'block', margin: '0 5px 0 auto'}}>
				<li onClick={(e) => {
					e.preventDefault()
					e.stopPropagation();
					setFormActive(true);
					}}
				>
					<EditIcon style={{marginRight: '5px', transform: 'translateY(2px)'}} />
					Edit
				</li>
				<li onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					}}
				>
					<Link className='black' to={'/forms/' + id + '/submissions'}>
						<MailIcon colour='#000' height='18px' style={{margin: '0 3px 0 -5px', transform: 'translateY(3px)'}} /> 
						Submissions
					</Link>
				</li>
				<li onClick={(e) => {
					deleteItem()
					e.preventDefault();
					e.stopPropagation();
					}}
				>
					<ClearIcon colour='#e02323' height='20px' style={{margin: '0 3px 0 -3px', transform: 'translateY(3px)'}} /> 
					Delete
				</li>
			</BTDMenu>


		</Link>

			{formActive &&
                <FormEditScreen
					itemId={id}
                    onClose={() => {
                        setFormActive(false);
                    }} 
                    onSubmit={() => {
                        onChange();
                        setFormActive(false);
                    }} 
                />
            }

		</>
	)
}

FormListItem.propTypes = {
	client: PropTypes.object,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isLoading: PropTypes.bool,
	onChange: PropTypes.func,
	name: PropTypes.string,
	number_of_submissions: PropTypes.number,
}

FormListItem.defaultProps = {
	client: {},
	itemId: null,
	id: null,
	isLoading: false,
	name: null,
	number_of_submissions: null,
	onChange: () => {alert('onChange is not set!')},
}

export default FormListItem;