// Packages
import { useState } from 'react'
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

// Components
import FormTemplate from 'components/BTDUI/FormTemplate';

// Other
import './styles.css'

const SubmissionsListItem = ({ browser, created_at, ip_address, number, values }) => {

	const [formActive, setFormActive] = useState(false);

	return (
		<>
		<div className="SubmissionsListItem card" onClick={() => {setFormActive(true)}}>

			<p className='opacity medium'>#{number}</p>

			<p className='black medium'>{ created_at ? dayjs(created_at).format('D MMM YYYY HH:mm') : ''}</p>

			<p className='black medium'>{ values ? values.name : ''}</p>

			<p className='black medium'>{ values ? values.email : ''}</p>

		</div>

			{formActive &&
                <FormTemplate onClose={() => {setFormActive(false)}} >
					<div className='SubmissionsListItem__modalDiv'>
						<div>
							<p className='semi-bold black'>Number:</p>
							<p className='medium black'>{number}</p>
						</div>
						<div>
							<p className='semi-bold black'>Date:</p>
							<p className='medium black'>{created_at ? dayjs(created_at).format('D MMM YYYY') : ''}</p>
						</div>
					</div>
					<div className='SubmissionsListItem__modalDiv'>
						<div>
							<p className='semi-bold black'>IP address:</p>
							<p className='medium black'>{ip_address}</p>
						</div>
						<div>
							<p className='semi-bold black'>Browser:</p>
							<p className='medium black'>{browser}</p>
						</div>
					</div>
					<div className='SubmissionsListItem__modalDiv'>
						<div>
							<p className='semi-bold black'>Name:</p>
							<p className='medium black'>{values ? values.name : ''}</p>
						</div>
						<div>
							<p className='semi-bold black'>Email:</p>
							<p className='medium black'>{ values ? values.email : ''}</p>
						</div>
					</div>
					<div className='SubmissionsListItem__modalDiv'>
						<div>
							<p className='semi-bold black'>Telephone:</p>
							<p className='medium black'>{ values ? values.telephone : ''}</p>
						</div>
						<div>
							<p className='semi-bold black'>Company:</p>
							<p className='medium black'>{ values ? values.company : ''}</p>
						</div>
					</div>
					<p className='semi-bold black'>Message:</p>
					<p className='medium black' dangerouslySetInnerHTML={{ __html: values && values.message ? values.message.replace(/(?:\r\n|\r|\n)/g, '<br />'): ''}}></p>

				</FormTemplate>
            }

		</>
	)
}

SubmissionsListItem.propTypes = {
	browser: PropTypes.string,
	created_at: PropTypes.string,
	number: PropTypes.number,
	values: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

SubmissionsListItem.defaultProps = {
	browser: '',
	created_at: null,
	number: null,
	values: {}
}

export default SubmissionsListItem;