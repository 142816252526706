// Packages
import { useState } from 'react';
import { useParams } from 'react-router-dom';

// Components
import SubmissionsLayout from 'modules/Forms/layouts/SubmissionsLayout';
import SubmissionsList from 'modules/Forms/components/SubmissionsList';

// other
import useApi from 'hooks/useApi';
import './styles.css';
import BTDPagination from 'components/BTDUI/BTDPagination';

const FormSubmissionsScreen = () => {

	const { formId } = useParams();

	const [page, setPage] = useState(1);

	const { data: form, isLoading: formLoading } = useApi('/forms/' + formId + '?with=client');
	const { data: submissions, isLoading: submissionsLoading, meta, onChange } = useApi('/forms/' + formId + '/submissions?page=' + page);

	return (
		<SubmissionsLayout {...form} isLoading={formLoading}>

			<SubmissionsList
				isLoading={submissionsLoading}
				items={submissions}
			/>

			{meta && meta.total > meta.per_page &&
				<BTDPagination
					currentPage={meta.current_page} 
					totalPages={meta.last_page}
					onChange={(value) => {
						setPage(value);
						onChange();
					}}
					style={{margin: '0 auto', width: 'max-content'}}
				/>
			}	

		</SubmissionsLayout>

	)

}

export default FormSubmissionsScreen