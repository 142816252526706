// Packages.
import { useState } from 'react';
import { Link } from 'react-router-dom';

// Components
import Container from 'components/BTDUI/Container';
import logo from 'assets/btd-logo.svg';
import Navigation from 'components/Navigation';
import MobileMenu from 'components/MobileMenu';
import Overlay from 'components/BTDUI/Overlay';

// other
import './styles.css';

const Header = () => {

	const [overlayActive, setOverlayActive] = useState(false);
	const [hambergerActive, setHamburgerActive] = useState(false);
	const [menuActive, setMenuActive] = useState(false);

	return (
		<>
		<header className="Header">

			<Container>

				<Link to='/'><img className='Header__logo' src={logo} alt="Behind The Dot logo"/></Link>

				<div className='Header__navigation'>
					<Navigation items={[
						{ label: 'Forms', url: '/' },
						{ label: 'Clients', url: '/clients' },
					]}/>
				</div>

				<div style={{height: '65px', width: '65px'}}></div>

				<button 
                    className={`hamburger hamburger--spin is-active-${hambergerActive}`} 
                    onClick={() => {
						if(! hambergerActive) {
							setHamburgerActive(true);
							setOverlayActive(true);
							setMenuActive(true);
						}
						else {
							setHamburgerActive(false);
							setMenuActive(false);
							setOverlayActive(false);
						}
                    }} 
                    type="button"
                >
                    <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                    </span>
                </button>

                {menuActive &&
					<MobileMenu onClick={() => {
						setMenuActive(false);
						setHamburgerActive(false);
						setOverlayActive(false);
					}} />
				}

			</Container>

			{overlayActive &&
		
				<Overlay onClick={() => {
					setMenuActive(false);
					setOverlayActive(false);
					setHamburgerActive(false);
				}} />
			}

		</header>


		</>
	)

}

export default Header;