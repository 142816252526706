// Packages
import PropTypes from 'prop-types';

// Components
import Container from 'components/BTDUI/Container';
import ScreenTitle from 'components/ScreenTitle';

// other
import './styles.css';

const ClientsLayout = ({ children }) => {

	return (
		<div className='FormLayout'>
			<Container>

				<ScreenTitle title='Clients'/>

				{children}

			</Container>
        </div>
	)
}

ClientsLayout.propTypes = {
	children: PropTypes.node,
}

ClientsLayout.defaultProps = {
	children: null,
}

export default ClientsLayout