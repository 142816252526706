// Packages
import PropTypes from 'prop-types';

// Components
import CheckedLink from 'components/BTDUI/CheckedLink';

// other
import './styles.css';

const MobileMenu = ({ onClick }) => {

    return(
        <div className="MobileMenu">

            <div className='MobileMenu__links'>

                <CheckedLink to='/' className='MobileMenu__links__link black' onClick={onClick}>Forms</CheckedLink>
                <CheckedLink to='/clients' className='MobileMenu__links__link black' onClick={onClick}>Clients</CheckedLink>

            </div>

        </div>
    )
}

MobileMenu.propTypes = {
    onClick: PropTypes.func,
}

MobileMenu.defaultProps = {
    onClick: () => {alert('onClick is not set!')},
}

export default MobileMenu;