// Packages
import { createContext, useState, useContext } from 'react';

const UIContext = createContext();

export const UIProvider = ({ children }) => {

    const [isLoading, setIsLoading] = useState(false);

    const setLoading = (value) => {
        setIsLoading(value)
    }

    const getLoading = () => {
        return isLoading ?? false;
    }

    return(

    <UIContext.Provider value ={{
        setLoading,
        getLoading
    }}>
        {children}
    </UIContext.Provider>
    )
}


export const useUI = () => {
    return useContext(UIContext);
} 