// Packages
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';

// Components
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import FormItemList from 'modules/Fields/components/FieldList';
import FormViewLayout from 'modules/Forms/layouts/ViewLayout';
import FieldCreateScreen from 'modules/Fields/screens/Create';
import BTDButton from 'components/BTDUI/BTDButton';
import CodeIcon from 'components/BTDUI/icons/CodeIcon';
import FormTemplate from 'components/BTDUI/FormTemplate';
import CopyToClipboard from 'components/BTDUI/CopyToClipboard';

// other
import useApi from 'hooks/useApi';
import './styles.css';

const FormViewScreen = () => {

	const { formId } = useParams();

	const { data: form, isLoading: formLoading, onChange: onFormChange } = useApi('/forms/' + formId + '?with=client');
	const { data: items, isLoading: itemsLoading, onChange: onItemsChange } = useApi('/forms/' + formId + '/fields');

	const [formActive, setFormActive] = useState(false);
	const [showEmbed, setShowEmbed] = useState(false);
	const [embed, setEmbed] = useState('');

	useEffect(() => {

		if(items && form) {

			var codeString = '<style>\n  .--form-field-error-message {\n  color: red !important;\n  text-align: left;\n  margin:5px 0 15px\n  }\n  .--form-field-error {\n  border: 2px solid #FF0000\n  }\n</style>\n\n<form method="post" data-formid="' + form.uuid + '">\n';

			for( let i = 0; i < items.length; i++ ) {

				codeString += '  <input type="' + items[i].type + '" name="' + items[i].name + '" ';
				codeString += items[i].is_required ? 'required ' : '';
				codeString += 'placeholder="' + items[i].label + '">\n';
			}

			codeString += '  <button>Send Message</button>\n</form>\n\n<script src="https://api.formcapture.behindthedot.com/js"></script>';
		}

		setEmbed(codeString);

	}, [items, form])

	return (
		<FormViewLayout {...form} isLoading={formLoading} onChange={onFormChange}>

			<div className='FormView__info'>
				<div>
					<p className='semi-bold blue'>UUID:</p>
					<p className='medium blue'>{formLoading ? <BTDSkeleton /> : form.uuid}</p>
				</div>
				<div>
					<p className='semi-bold blue'>Recipients:</p>
					<p className='medium blue'>{formLoading ? <BTDSkeleton /> : form.recipients}</p>
				</div>
				<div>
					<p className='semi-bold blue'>Redirect URL:</p>
					<p className='medium blue'>{formLoading ? <BTDSkeleton /> : form.redirect_url}</p>
				</div>
				<div>
					<p className='semi-bold blue'>Submissions:</p>
					<p className='medium blue'>{formLoading ? <BTDSkeleton /> : form.number_of_submissions}</p>
				</div>

				<BTDButton
					label='Show embed'
					onClick={() => setShowEmbed(true)}
					startIcon={<CodeIcon colour='#fff' height='28px' style={{marginRight: '8px', transform: 'translateY(2px)'}} width='28px' />}
					style={{bottom: '20px', position: 'absolute', right: '20px'}}
				/>
			</div>

			<h3 className='FormView__subtitle'>Fields</h3>

			<FormItemList
				isLoading={itemsLoading}
				items={items}
				onChange={() => {onItemsChange()}}
			/>

			<BTDButton
				label='Add field'
				onClick={() => {setFormActive(true)}} 
				style={{ margin: '0 0 0 auto',}}
			/>

			{formActive &&
                <FieldCreateScreen
					formId={formId}
                    onClose={() => {
                        setFormActive(false);
                    }} 
                    onSubmit={() => {
                        onItemsChange();
                        setFormActive(false);
                    }} 
                />
            }

			{showEmbed &&
				<FormTemplate onClose={() => {setShowEmbed(false)}}>
					<pre>{embed}</pre>
					<div style={{ margin: '20px 0 0 auto', width: 'max-content'}}>
						<CopyToClipboard style={{ height: '30px', width: '30px'}} text={embed} />
						</div>
				</FormTemplate>
			}

			
		</FormViewLayout>

	)

}

export default FormViewScreen;
