// Packages
import { useState } from 'react'
import PropTypes from 'prop-types';

// Components
import Container from 'components/BTDUI/Container';
import ScreenTitle from 'components/ScreenTitle';
import BTDSkeleton from 'components/BTDUI/BTDSkeleton';
import BTDButton from 'components/BTDUI/BTDButton';
import EditIcon from 'components/BTDUI/icons/EditIcon';
import FormEditScreen from 'modules/Forms/screens/Edit';

// other
import './styles.css';

const FormViewLayout = ({ children, client, id, isLoading, name, onChange }) => {

	const [formActive, setFormActive] = useState(false);

	return (
		<div className='FormLayout'>
			<Container>

				<ScreenTitle title={isLoading ? <BTDSkeleton /> : client ? client.name + ' - ' + name : name}>
					<BTDButton
						label='Edit form'
						startIcon={<EditIcon colour='#fff' height='16px' style={{display: 'block', marginRight: '8px', transform: 'translateY(1px)'}} width='16px' />}
						onClick={() => {setFormActive(true)}}
					/>
				</ScreenTitle>

				{children}

			</Container>
			
			{formActive &&
                <FormEditScreen
					itemId={id}
                    onClose={() => {
                        setFormActive(false);
                    }} 
                    onSubmit={() => {
                        onChange();
                        setFormActive(false);
                    }} 
                />
            }

        </div>
	)
}

FormViewLayout.propTypes = {
	children: PropTypes.node,
	client: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isLoading: PropTypes.bool,
	name: PropTypes.string,
	onChange: PropTypes.func
}

FormViewLayout.defaultProps = {
	children: null,
	client: {},
	id: '',
	isLoading: true,
	name: '',
	onChange: () => {alert('onChange is not set!')}
}

export default FormViewLayout