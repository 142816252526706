// Libraries
import PropTypes from 'prop-types';

// Assets
import './styles.css';


const ScreenTitle = props => {

	// Props
	const { title, style } = props;

	return (
		<div className="ScreenTitle container" style={style}>

			<div className="ScreenTitle__header">
				<h1 className="ScreenTitle__header__title">{title}</h1>
			</div>

			<div className="ScreenTitle__body">
				{props.children}
			</div>

		</div>
	);
}


// Prop Tyles
ScreenTitle.propTypes = {
	title: PropTypes.string.isRequired,
	style:PropTypes.object,
}

ScreenTitle.defaultProps = {
	title: '',
	style: {}
}


export default ScreenTitle;