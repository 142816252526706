// Packages
import { useState } from 'react'

// Components
import FormsList from 'modules/Forms/components/List';
import FormCreateScreen from '../Create';
import AddIcon from 'components/BTDUI/icons/BTDFAB';
import FormsLayout from 'modules/Forms/layouts';

// Other
import useApi from 'hooks/useApi';

const FormsListScreen = () => {

    const { data: items, isLoading, onChange } = useApi('/forms?with=client');

    const [formActive, setFormActive] = useState(false);

    return(
        <FormsLayout title='Forms'>

            <FormsList items={items} isLoading={isLoading} onChange={() => {onChange()}} />

            <AddIcon onClick={() => {
                setFormActive(true);
            }} />

            {formActive &&
                <FormCreateScreen
                    onClose={() => {
                        setFormActive(false);
                    }} 
                    onSubmit={() => {
                        onChange();
                        setFormActive(false);
                    }} 
                />
            }

        </FormsLayout>
    )

}

export default FormsListScreen;