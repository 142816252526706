// Packages
import PropTypes from 'prop-types';

// Context
import { useSnackbar } from 'context/Snackbar';

// Other
import './styles.css';
import BTDTooltip from 'components/BTDUI/BTDTooltip';

const CopyToClipboard = ({ style, text }) => {

    const { showSnackbar } = useSnackbar();

    const handleClick = () => {

        navigator.clipboard.writeText(text);
        showSnackbar('Copied to clipboard!', 'info');
    }

    return(
        <BTDTooltip text='Copy to clipboard'>
            <div className='CopyToClipboard'onClick={handleClick} style={style} />
        </BTDTooltip>
    )
}

CopyToClipboard.propTypes = {
    style: PropTypes.object,
    text: PropTypes.string
}

CopyToClipboard.defaultProps = {
    style: {},
    text: ''
}

export default CopyToClipboard;