// Packages
import PropTypes from 'prop-types';

// Context
import { useUI } from 'context/UI';

// Components
import BTDProgress from '../BTDProgress';

// Other
import './styles.css';


const BTDButton = ({ colour, disabled, formButton, label, type, startIcon, style, onClick, size, target, url }) => {

	const { getLoading } = useUI();

	if(getLoading() && formButton) {

		return(

			<BTDProgress type='circular' />
		)
	}

	return (
		<>
		
		{url ?
				<a 
					href={url} 
					className={`BTDButton BTDButton--colour-${colour} BTDButton--size-${size} BTDButton--type-${type} ${disabled ? 'disabled' : ''} ${startIcon ? 'startIcon' : ''}`} 
					style={style}
					target={target}
				>
					{startIcon}
					{label}
				</a>
			:
		
			<button onClick={() => {

				if( ! disabled ) {

					if ( onClick )
					{
						onClick();
					}
				}

			}} style={style} className={`BTDButton BTDButton--colour-${colour} BTDButton--size-${size} BTDButton--type-${type} ${disabled ? 'disabled' : ''} ${startIcon ? 'startIcon' : ''}`}>
				{startIcon}
				{label}

			</button>
		}
		</>
	);

}

BTDButton.propTypes = {
	colour: PropTypes.string,
	disabled: PropTypes.bool,
	formButton: PropTypes.bool,
	label: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	size: PropTypes.string,
	startIcon: PropTypes.object,
	style: PropTypes.object,
	type: PropTypes.string,
	url: PropTypes.string,
}

BTDButton.defaultProps = {
	colour: 'default',
	disabled: false,
	formButton: false,
	label: '',
	onClick: () => {},
	size: '',
	startIcon: null,
	style: {},
	type: '',
	url: null
}


export default BTDButton;