// Packages
import PropTypes from 'prop-types';
import CheckedLink from 'components/BTDUI/CheckedLink';

// Other
import './styles.css';
import BTDMenu from 'components/BTDUI/BTDMenu';
import EllipsisIcon from 'components/BTDUI/icons/EllipsisIcon';


const Navigation = ({ items }) => {

	return (
		<nav className="Navigation">

			<ul className="Navigation__list">

			{items.map((item) => {

				return (
					<li key={item.url} className="Navigation__list__item">
						<CheckedLink to={item.url} className="Navigation__list__item__link" label={item.label} />
					</li>
				)

			})}

				{/* <li className="Navigation__list__item">
					<BTDMenu icon={<EllipsisIcon height="20px" style={{transform: 'rotate(90deg)'}} />}>
						<div><CheckedLink to='/settings' label='Settings'/></div>
					</BTDMenu>
				</li> */}

			</ul>

		</nav>
	)
}

Navigation.defaultProps = {
	items: []
}

Navigation.propTypes = {
	items: PropTypes.array.isRequired
}

export default Navigation;