// Packages
import { createContext, useContext, useState } from 'react';

const NavContext = createContext();

export const NavProvider = ({ children }) => {

    const [ unsavedChanges, setUnsavedChanges ] = useState(null);

    return (
        <NavContext.Provider value={{
            unsavedChanges,
            setUnsavedChanges
        }}>
        {children}
        </NavContext.Provider>
    );

}

export const useNav = () => {
    return useContext(NavContext);
};