// Packages
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import FormTemplate from 'components/BTDUI/FormTemplate';
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDButton from 'components/BTDUI/BTDButton';


const ClientForm = ({ errors, form, onClose, onSubmit, type, }) => {

    const [data, setData] = useState({})
	const [changedData, setChangedData] = useState({})
    

    // Load form values into form
	useEffect(() => {

		if ( form )
		{
            setData(form);
		}

	}, [form]);


    return(
        <FormTemplate onClose={() => {onClose()}}>

            <h3 className="form__title medium">{type === 'create' ? 'Create client' : 'Edit client'}</h3>
        
            <BTDTextField 
                error={errors && errors.name ? errors.name : null} 
                label='Client name'
                onChange={(value) => {
                    setData({...data, name: value});
                    setChangedData({...changedData, name: value});
                }} 
                required={true}
                value={data.name ?? ''} 
            />

            <BTDButton 
                formButton={true}
                label={type === 'create' ? 'Save client' : 'Update client'}
                onClick={() => {onSubmit(changedData)}} 
            ></BTDButton>

        </FormTemplate>
    )

}

ClientForm.propTypes = {
	errors: PropTypes.object,
	form: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onClose: PropTypes.func,
	onSubmit: PropTypes.func,
    type: PropTypes.string
}

ClientForm.defaultProps = {
    errors: {},
    form: null,
	onSubmit: () => {alert('onSubmit is not set')},
	onClose: () => {alert('onClose is not set')},
    type: 'create'
}

export default ClientForm;