// Packages
import PropTypes from 'prop-types';

// other
import './styles.css';

const ClearIcon = ({ colour, height, onClick, style, width }) => {
    
    return(
        <div className="ClearIcon" onClick={onClick} style={style}>
            <svg height={height} viewBox="0 0 24 24" width={width}>
                <g id="Layer_2" dataname="Layer 2" fill={colour}>
                    <path d="m12 1a11 11 0 1 0 11 11 11.013 11.013 0 0 0 -11-11zm4.242 13.829a1 1 0 1 1 -1.414 1.414l-2.828-2.829-2.828 2.829a1 1 0 0 1 -1.414-1.414l2.828-2.829-2.828-2.829a1 1 0 1 1 1.414-1.414l2.828 2.829 2.828-2.829a1 1 0 1 1 1.414 1.414l-2.828 2.829z"/>
                </g>
            </svg>
        </div>
    )
}

ClearIcon.propTypes = {
    colour: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    style: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

ClearIcon.defaultProps = {
    colour: "currentColor",
    height: "25px",
    onClick: () => {},
    style: {},
    width: "25px", 
}

export default ClearIcon;