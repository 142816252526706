// Packages
import PropTypes from 'prop-types'

// Assets
import './styles.css'


const Overlay = ({ children, onClick, colour }) => {

	function overlayClicked() {

		onClick(true);
	}

	return (
		<div className={`Overlay Overlay-${colour ?? ''}`} onClick={overlayClicked}>
			{children}
		</div>
	)
}

Overlay.propTypes = {
	onClick: PropTypes.func,
}

Overlay.defaultProps = {
	onClick: () => {alert('onClick is not set!')}
}

export default Overlay