// Packages
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

// Context
import { useSnackbar } from 'context/Snackbar';

// Components
import BTDProgress from 'components/BTDUI/BTDProgress';

const Auth = props => {

	const { showSnackbar } = useSnackbar();

	/*
	Import variables/methods from the Auth0 context
	*/
	const {
		isLoading,
		isAuthenticated,
		error,
		loginWithRedirect,
		user,
		getAccessTokenSilently
	} = useAuth0();


  	// State
	const [ accessToken, setAccessToken ] = useState(null);


	/*
	Auth0 is processing - show full-screen loading spinner
	*/	
	if ( isLoading ) 
	{
		return (
			<div 
                style={{ 
                        alignItems: 'center',
                        display: 'flex',
                        height: '100vh', 
                        justifyContent: 'center',
                        width: '100%'
                    }}
            >
                <BTDProgress type='circular' height={100} width={100} />
            </div>
		);
	}


	/*	
	An error has occurred with the Auth0 communication
	*/
	if ( error ) 
	{
		showSnackbar(error.message, 'error')
		return (
			<></>
		);
	}


	if ( isAuthenticated ) 
	{
		/*
		The user is authenticated and has a valid Auth0 token
		*/


		// Get an access token for API requests
		const getToken = async () => {

			const accessToken = await getAccessTokenSilently();

			sessionStorage.setItem('access-token', accessToken);
			setAccessToken(accessToken);
		}

		getToken();


		if ( accessToken !== null )
		{
			return props.children;
		}
		else 
		{
			return (<div 
                style={{ 
                        alignItems: 'center',
                        display: 'flex',
                        height: '100vh', 
                        justifyContent: 'center',
                        width: '100%'
                    }}
            >
                <BTDProgress type='circular' height={100} width={100} />
            </div>);
		}

		
	}
	else 
	{
		/*
		The user needs to login, redirect to Auth0
		*/
		const returnTo = window.location.pathname + window.location.search + window.location.hash;
		loginWithRedirect({ 
			appState: { returnTo: returnTo }
		});
	}
}

export default Auth;