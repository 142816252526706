// Packages
import PropTypes from 'prop-types';

// other
import './styles.css';

const MailIcon = ({ colour, height, onClick, style, width }) => {
    
    return(
        <div className="MailIcon" onClick={onClick} style={style}>
            <svg fill={colour} height={height} width={width} version="1.1" viewBox="0 0 512 512">
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                <g id="SVGRepo_iconCarrier"> 
                    <g id="7935ec95c421cee6d86eb22ecd12600d"> 
                        <path style={{display: 'inline'}} d="M170.591,239.406L1.584,429.263c-0.433-1.911-1.084-3.759-1.084-5.852V88.589 c0-2.541,0.71-4.837,1.356-7.144L170.591,239.406z M493.987,65.524c-1.238-0.277-2.424-0.876-3.79-0.876H21.819 c-1.297,0-2.456,0.599-3.769,0.876l237.961,222.734L493.987,65.524z M262.808,313.021c-1.954,1.869-4.372,2.777-6.797,2.777 c-2.419,0-4.842-0.908-6.775-2.777l-62.21-58.217L16.656,446.145c1.698,0.523,3.364,1.207,5.163,1.207h468.378 c1.89,0,3.534-0.684,5.243-1.207L325.082,254.804L262.808,313.021z M341.494,239.406l168.98,189.836 c0.459-1.911,1.025-3.759,1.025-5.831V88.589c0-2.499-0.62-4.837-1.26-7.133L341.494,239.406z"> </path> 
                    </g> 
                </g>
            </svg>
        </div>
    )
}

MailIcon.propTypes = {
    colour: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    style: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

MailIcon.defaultProps = {
    colour: "currentColor",
    height: "25px",
    onClick: () => {},
    style: {},
    width: "25px", 
}

export default MailIcon;