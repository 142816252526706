// Packages
import { useEffect, useState, useCallback } from 'react'
import axios from 'axios';

// Context
import { useUI } from 'context/UI'
import { useSnackbar } from 'context/Snackbar'

// Other
import Config from 'other/config';


const useApi = (url, runGet=true ) => {

	const [ data, setData ] = useState([])
	const [ meta, setMeta ] = useState([])
	const [ isLoading, setIsLoading ] = useState(true)
	const [ isError, setIsError ] = useState(true)
	const [ errors, setErrors ] = useState({})
	const [ refresh, setRefresh ] = useState(false)
    const [params, setParams] = useState('');

	const { setLoading } = useUI();
    const { showSnackbar } = useSnackbar();

    const getAccessToken = async () => {

        return sessionStorage.getItem('access-token');
    }

	useEffect(() => 
	{
        if( runGet ) {

            setLoading(true);

            const fetchData = async () => {

                const token = await getAccessToken();

                try {

                    const response = await axios.get(Config.API_URL + url + params, {
                        headers: {
                            Authorization: 'Bearer ' + token
                        }
                    })
                        
                    setData(response.data.data);
                    setMeta(response.data.meta);

                    setIsError(false);
                    setIsLoading(false);


                } catch (error) {
                    setIsError(true);
                    setErrors(error.response.data.errors);
                    showSnackbar(error.response.data.message, 'error');

                }
                
                setLoading(false);
            }

            fetchData();
        }

	}, [url, refresh, params])


    const get = async (successMessage) => 
	{
        setIsLoading(true);
        setLoading(true);

        const token = await getAccessToken();

        try 
        {
            const response = await axios.get(Config.API_URL + url, {
                headers: {
                    Authorization: 'Bearer ' + token
                }});

            setData(response.data.data);

            setIsError(false);
            setIsLoading(false)

            showSnackbar(successMessage, 'success');
            
            setIsLoading(false);
            setLoading(false);

            return response.data.data;
        } 
        catch (error) 
        {
            setIsError(true);
            setErrors(error.response.data.errors);
            showSnackbar(error.response.data.message, 'error');

            setIsLoading(false);
            setLoading(false);

            return false;
        }
    };

	const put = async (postData, successMessage) => 
	{
        setIsLoading(true);
        setLoading(true);

        const token = await getAccessToken();

        try 
        {
            const response = await axios.put(Config.API_URL + url, postData, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });

            setData(response.data.data);

            setIsError(false);
            setIsLoading(false)

            showSnackbar(successMessage, 'success');
            
            setIsLoading(false);
            setLoading(false);

            return response.data.data;
        } 
        catch (error) 
        {
            setIsError(true);
            setErrors(error.response.data.errors);
            showSnackbar(error.response.data.message, 'error');

            setIsLoading(false);
            setLoading(false);

            return false;
        }

        
    };

    const post = async (postData, successMessage) => 
    {
        setIsLoading(true);
        setLoading(true);

        const token = await getAccessToken();

        try 
        {
            const response = await axios.post(Config.API_URL + url, postData, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });


            if( successMessage ) {

                showSnackbar(successMessage, 'success');
            }
            
            setData(response.data.data);

            setIsError(false);
            setIsLoading(false);
            setLoading(false);

            return response.data.data;

        } 
        catch (error) 
        {
            setIsError(true);
            setErrors(error.response.data.errors);
            showSnackbar(error.response.data.message, 'error');
            setIsLoading(false);
            setLoading(false);

            return false;
        }
        
    };


    const destroy = async (itemType, successMessage) => 
    {
        if ( ! window.confirm('Are you sure that you want to delete this '+ itemType + '?') )
        {
            return false;
        }

        setIsLoading(true);
        setLoading(true);

        const token = await getAccessToken();

        try 
        {
            const response = await axios.delete(Config.API_URL + url, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });

            setData(response.data.data);
            setIsError(false);
            setIsLoading(false)

            showSnackbar(successMessage, 'success');

            setIsLoading(false);
            setLoading(false);

            return true;
            
        } 
        catch (error) 
        {
            setIsError(true);
            setErrors(error.response.data.errors);
            showSnackbar(error.response.data.message, 'error');

            setIsLoading(false);
            setLoading(false);
            return false;
        }

    };

    const onChange = useCallback(() => {
        setRefresh(prevRefresh => !prevRefresh);
    }, []);

	return { 
        data,
        meta,
		isLoading, 
		isError, 
        errors,
        get,
		put, 
		post,
        destroy,
        onChange,
        setParams
	}
}

export default useApi