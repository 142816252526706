// Packages
import PropTypes from 'prop-types';

// other
import './styles.css';

const EditIcon = ({ colour, height, onClick, style, width }) => {
    
    return(
        <div className="EditIcon" onClick={onClick} style={style}>
            <svg height={height} viewBox="0 0 492.49284 492" width={width} fill={colour} xmlns="http://www.w3.org/2000/svg">
                <path d="m304.140625 82.472656-270.976563 270.996094c-1.363281 1.367188-2.347656 3.09375-2.816406 4.949219l-30.035156 120.554687c-.898438 3.628906.167969 7.488282 2.816406 10.136719 2.003906 2.003906 4.734375 3.113281 7.527344 3.113281.855469 0 1.730469-.105468 2.582031-.320312l120.554688-30.039063c1.878906-.46875 3.585937-1.449219 4.949219-2.8125l271-270.976562zm0 0"/><path d="m476.875 45.523438-30.164062-30.164063c-20.160157-20.160156-55.296876-20.140625-75.433594 0l-36.949219 36.949219 105.597656 105.597656 36.949219-36.949219c10.070312-10.066406 15.617188-23.464843 15.617188-37.714843s-5.546876-27.648438-15.617188-37.71875zm0 0"/>
            </svg>
        </div>
    )
}

EditIcon.propTypes = {
    colour: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    style: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

EditIcon.defaultProps = {
    colour: "currentColor",
    height: "17px",
    onClick: () => {},
    style: {},
    width: "17px", 
}

export default EditIcon;