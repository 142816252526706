// Packages
import React from 'react';
import PropTypes from 'prop-types';

// Other
import './styles.css'; 

const BTDSkeleton = ({ borderRadius, height, width }) => {

    const style = {
        width,
        height,
        borderRadius,
    };

    return <span className="BTDSkeleton" style={style}></span>;
};

BTDSkeleton.propTypes = {
    borderRadius: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

BTDSkeleton.defaultProps = {
    borderRadius: '50px',
    height: '16px',
    width: '100%',
}

export default BTDSkeleton;
